import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import * as React from 'react'
import { ActivityIndicatorStyled } from './activity-indicator.component.style'

export interface ActivityIndicatorProps {
  type: 'spinner' | 'circle-notch' | 'sync' | 'cog'
  size?: SizeProp
  light?: boolean
}

export const ActivityIndicator: React.SFC<ActivityIndicatorProps> = (props: ActivityIndicatorProps) => {
  const { type, ...others } = props
  return <ActivityIndicatorStyled {...others} icon={type} spin={true} />
}
