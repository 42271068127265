import { GridSettings } from 'atomic/legacy/obj.grid'
import { Theme } from 'utils/theme'

let brand: Partial<Theme> = {}
if (process.env.GATSBY_MARCA === 'FLEURY') {
  brand = require('fleury').theme
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  brand = require('weinmann').theme
} else if (process.env.GATSBY_MARCA === 'FM') {
  brand = require('felippe-mattoso').theme
}

export const theme: Theme = {
  ...(brand as Theme),
  ...GridSettings
}
