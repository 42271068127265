import * as React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FaIcon = {
  ArrowLeft: props => <FontAwesomeIcon {...props} icon="long-arrow-alt-left" />,
  ArrowRight: props => <FontAwesomeIcon {...props} icon="long-arrow-alt-right" />,
  Ban: props => <FontAwesomeIcon {...props} icon="ban" />,
  Bus: props => <FontAwesomeIcon {...props} icon="bus" />,
  Calendar: props => <FontAwesomeIcon {...props} icon={['far', 'calendar-alt']} />,
  Check: props => <FontAwesomeIcon {...props} icon="check" />,
  ChevronDown: props => <FontAwesomeIcon {...props} icon="chevron-down" />,
  ChevronLeft: props => <FontAwesomeIcon {...props} icon="chevron-left" />,
  ChevronRight: props => <FontAwesomeIcon {...props} icon="chevron-right" />,
  ChevronUp: props => <FontAwesomeIcon {...props} icon="chevron-up" />,
  Clock: props => <FontAwesomeIcon {...props} icon={['far', 'clock']} />,

  Close: props => <FontAwesomeIcon {...props} icon="times" />,
  Download: props => <FontAwesomeIcon {...props} icon={['far', 'arrow-alt-circle-down']} />,
  Envelope: props => <FontAwesomeIcon {...props} icon={['far', 'envelope']} />,
  FileUpload: props => <FontAwesomeIcon {...props} icon="upload" />,
  FlashAlert: props => <FontAwesomeIcon {...props} icon="times-circle" />,
  FlashInfo: props => <FontAwesomeIcon {...props} icon="info-circle" />,
  FlashSuccess: props => <FontAwesomeIcon {...props} icon="check-circle" />,
  FlashWarning: props => <FontAwesomeIcon {...props} icon="exclamation-circle" />,
  Globe: props => <FontAwesomeIcon {...props} icon="globe-americas" />,
  Heart: props => <FontAwesomeIcon {...props} icon="heart" />,
  Image: props => <FontAwesomeIcon {...props} icon="image" />,
  Menu: props => <FontAwesomeIcon {...props} icon="bars" />,
  Microphone: props => <FontAwesomeIcon {...props} icon="microphone" />,
  Phone: props => <FontAwesomeIcon {...props} icon="phone" />,
  Star: props => <FontAwesomeIcon {...props} icon="star" />,
  Search: props => <FontAwesomeIcon {...props} icon="search" />,
  StarHalf: props => <FontAwesomeIcon {...props} icon="star-half" />,
  StepperMinus: props => <FontAwesomeIcon {...props} icon="minus" />,
  StepperPlus: props => <FontAwesomeIcon {...props} icon="plus" />,
  Warning: props => <FontAwesomeIcon {...props} icon="exclamation-triangle" />,
  Whatsapp: props => <FontAwesomeIcon {...props} icon={['fab', 'whatsapp']} />,
  Instagram: props => <FontAwesomeIcon {...props} icon={['fab', 'instagram']} />,
  Facebook: props => <FontAwesomeIcon {...props} icon={['fab', 'facebook-square']} />,
  FacebookNew: props => <FontAwesomeIcon {...props} icon={['fab', 'facebook-f']} />,
  Messenger: props => <FontAwesomeIcon {...props} icon={['fab', 'facebook-messenger']} />,
  Linkedin: props => <FontAwesomeIcon {...props} icon={['fab', 'linkedin']} />,
  Twitter: props => <FontAwesomeIcon {...props} icon={['fab', 'twitter']} />,
  Youtube: props => <FontAwesomeIcon {...props} icon={['fab', 'youtube']} />
}
