import { Theme } from 'utils/theme'
import { Color } from 'atomic'
import { BrandColor } from './colors'

export const theme: Theme = {
  typography: {
    primary: 'ASAP'
  },
  color: {
    primary: BrandColor.Red,
    clientAccessory: BrandColor.DarkRed,
    accessory: BrandColor.Gray,

    doctorAccessory: BrandColor.Gray,
    warning: BrandColor.Yellow,
    success: BrandColor.Green,
    alert: BrandColor.RedAlert,

    background: Color.White,
    footer: BrandColor.Gray
  }
}
