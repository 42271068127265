import * as React from 'react'
import { hasNavigator, hasWindow } from './platform'

export interface InternetContainerProps {
  children: (online: boolean) => JSX.Element
}

export interface InternetContainerState {
  online: boolean
}

export class InternetContainer extends React.Component<InternetContainerProps, InternetContainerState> {
  constructor(props: InternetContainerProps) {
    super(props)
    this.state = { online: true }
  }

  componentDidMount() {
    if (hasWindow()) {
      window.addEventListener('online', this.updateOnlineStatus)
      window.addEventListener('offline', this.updateOnlineStatus)
    }
  }

  componentWillUnmount() {
    if (hasWindow()) {
      window.removeEventListener('online', this.updateOnlineStatus)
      window.removeEventListener('offline', this.updateOnlineStatus)
    }
  }

  updateOnlineStatus = () => {
    if (hasNavigator()) {
      this.setState({ online: navigator.onLine })
    }
  }

  render() {
    return this.props.children(this.state.online)
  }
}
