import * as queryString from 'query-string'
import { SearchQueryParam } from '../site/src/components/legacy/mol.search/debounced-search.utils'
import { diactriclessKebabCase } from './string'

export const getDetailPageUrl = (typeSlug: string, pageSlug: string, subItemSlug = '') => {
  if (!typeSlug || !pageSlug) {
    console.error('getDetailPageUrl::error: Invalid input: ', 'typeSlug', typeSlug, 'pageSlug', pageSlug, 'subItemSlug', subItemSlug)
  }
  return normalizeUrl(`/${typeSlug}/${diactriclessKebabCase(pageSlug)}/${diactriclessKebabCase(subItemSlug)}`)
}
export const normalizeUrl = (url: string) => {
  if (!url) {
    return url
  }

  // remove duplicated `/`: https://stackoverflow.com/questions/24381480/remove-duplicate-forward-slashes-from-the-url
  let normalizedUrl = url.replace(/([^:]\/)\/+/g, '$1')

  if (/^\/\//.test(normalizedUrl)) {
    normalizedUrl = normalizedUrl.slice(1)
  }

  // SEO improvement: remove last `/` char if needed (to improve url consistency)
  if (normalizedUrl[normalizedUrl.length - 1] === '/') {
    return normalizedUrl.slice(0, -1)
  }

  return normalizedUrl
}

export const doctorUrlPrefix = 'medico'
export const isDoctorUrl = (path: string) => path.startsWith(doctorUrlPrefix) || path.startsWith(`/${doctorUrlPrefix}`)

export const getClientAndDoctorUrl = (location: Location) => {
  const currentPath = `${location.pathname}${location.search}`
  const clientPath = normalizeUrl(currentPath.replace(doctorUrlPrefix, ''))
  const doctorPath = normalizeUrl(`${doctorUrlPrefix}/${clientPath}`)
  return [clientPath, doctorPath]
}

export const isExternalUrl = (url: string) => {
  return url.trim().match(/^(http)|(tel)|(mailto)/i)
}

export const insertDoctorPrefix = (clientUrl: string) => `/${doctorUrlPrefix}${clientUrl}`

export const getSearchQueryParam = (busca: string = '') => {
  return busca ? `?${queryString.stringify({ busca: busca && busca.trim() } as SearchQueryParam)}` : ''
}
