import { Spacing } from 'atomic/legacy/obj.constants'
import React from 'react'
import styled from 'styled-components'

interface BoxProps {
  // src: https://www.w3schools.com/cssref/css3_pr_align-items.asp
  hAlign?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit'
  // src: https://www.w3schools.com/cssref/css3_pr_justify-content.asp
  vAlign?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'initial' | 'inherit'
}

export interface HboxProps extends BoxProps {
  wrap?: boolean
}

const HboxStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: ${(props: HboxProps) => (props.hAlign ? props.hAlign : 'stretch')};
  &.NewSpacingHorarioAtendimento{
    margin: 10px 0;
  }
`

export const Hbox: any = HboxStyled
Hbox.displayName = 'Hbox'

Hbox.Item = styled(({ wrap, vAlign, hAlign, ...props }) => <div {...props} />)`
  flex-direction: column;
  display: flex;
  ${(props: HboxProps) => (!props.wrap ? 'flex: 1' : '')};
  justify-content: ${(props: HboxProps) => (props.vAlign ? props.vAlign : 'flex-start')};
  align-items: ${(props: HboxProps) => (props.hAlign ? props.hAlign : 'stretch')};
`
Hbox.Item.displayName = 'Hbox.Item'

Hbox.Separator = styled.div`
  width: ${Spacing.Small};
`
Hbox.Separator.displayName = 'Hbox.Separator'

/** https://css-tricks.com/the-peculiar-magic-of-flexbox-and-auto-margins/ */
export const AutoMargin = styled.div`
  margin: auto;
`

export const Separator = styled.div`
  margin-bottom: ${Spacing.Large};
`

export const SeparatorMediaAndContact = styled.div`
  margin-bottom: ${Spacing.Medium};
`

export const SeparatorW = styled.div`
  margin-bottom: 10px;
  width: 76.28px;
  height: 3.92px;

  background: #018391;
  border-radius: 3px;
`

export const SeparatorWV = styled.div`
  margin: 36px;
  width: 0.28px;
  height: 188px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #B3DCE0;
  border-radius: 3px;
`

export const LargeSeparator = styled.div`

  margin-bottom: ${Spacing.Large};
  @media all and (min-width: 64em) {
    margin-bottom: ${Spacing.XXLarge};
  }
`
