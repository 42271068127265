import { GlobalStyled } from 'atomic'
import { theme } from '@root/src/components/obj.theme'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { ClientDoctorSelectContext } from '@root/src/context/client-doctor-select'
import VisitCountDataSource from '@root/src/data/visit-count.datasource'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'

if (process.env.GATSBY_MARCA === 'FLEURY') {
  require('fleury/font')
} else if (process.env.GATSBY_MARCA === 'WEINMANN') {
  require('weinmann/font')
} else if (process.env.GATSBY_MARCA === 'FM') {
  require('felippe-mattoso/font')
}

library.add(fab, fas, far)

export const wrapRootElement = ({ element }) => (
  <ClientDoctorSelectContext.Provider value={{ clientUrl: '/', doctorUrl: '/medico' }}>
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <html lang="pt-br" />
        </Helmet>
        <GlobalStyled />
        {element}
      </>
    </ThemeProvider>
  </ClientDoctorSelectContext.Provider>
)

export const onRouteUpdate = ({ location, prevLocation }) => {
  VisitCountDataSource.incrementVisitCount()
}

/** https://github.com/gatsbyjs/gatsby/issues/10435#issuecomment-446627549 */
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
