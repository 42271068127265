import { Border } from 'atomic/legacy/obj.constants'

import styled from 'styled-components'

export const Divisor = styled.div`
  height: ${Border.Width};
  width: 100%;
  background-color: ${Border.Color};
  &.divisorUp {
    background-color: #B9B9B9;
    margin: 0px 0 5px 0;
  }
  &.divisorDown {
    background-color: #B9B9B9;
    margin: 5px 0 0 0;
    
  }
`
