// https://stackoverflow.com/a/13924997/3670829
export const textMaxLines = (maxLines: number, lineHeightInPx: number, adjustment: number = 0) => `
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
  line-height: ${lineHeightInPx}px;
  max-height: ${lineHeightInPx * maxLines + adjustment}px;
  margin: 0;
`

// https://css-tricks.com/snippets/css/remove-gray-highlight-when-tapping-links-in-mobile-safari/
export const highlightStyle = `
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0.05);
`

// https://stackoverflow.com/q/7073484/3670829
export const triangleStyle = (size: number, color) => `
  width: 0;
  height: 0;
  border-left: ${size}px solid transparent;
  border-right: ${size}px solid transparent;
  border-bottom: ${size}px solid ${color};
`

export const onlyMobileStyle = `
@media all and (min-width: 48em) {
  display: none;
}
`

export const onlyDesktopStyle = `
@media all and (max-width: 48em) {
  display: none;
}
`
