// We used class rather than enums since enums don't support computed values
export class Color {
  public static readonly Black = 'black'
  public static readonly White = 'white'
  public static readonly Neutral = '#F8F8F8'

  public static readonly GrayMLight = '#F7F6F4'
  public static readonly GrayXLight = '#EBEBEB'
  public static readonly GrayLight = '#EFEFEF'
  public static readonly Gray = '#B9B9B9'
  public static readonly GrayDark = '#77787B'
  public static readonly GrayXDark = '#464646'
}

export enum FontWeight {
  Bold = 'bold',
  SemiBold = 600,
  Normal = 'normal',
  Lighter = 200
}

export enum FontSize {
  XXSmall = '12px',
  XSmall = '14px',
  Small = '16px',
  Medium = '18px',
  Large = '24px',
  XLarge = '32px'
}

export enum Spacing {
  XSmall = '4px',
  Small = '8px',
  Medium = '16px',
  Large = '22px',
  XLarge = '32px',
  Intermediary = '52px',
  XXLarge = '60px'
}

export enum IconSize {
  Small = '12px',
  Medium = '16px',
  Large = '20px'
}

export const Border = {
  Color: Color.GrayLight,
  Width: '2px',
  Radius: '8px',
  RadiusLarge: '24px'
}

export const HeaderHeight = {
  Mobile: 64,
  Desk: 108
}

export const DrawerWidth = '256px'
export const TransitionDuration = '.3s'

// Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
// of at least alpha and numeric characters increases the risk of a brute force attack.
export enum PasswordLength {
  Max = 18,
  Min = 7
}

export enum AspectRatio {
  Square = 1
}
