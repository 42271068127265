import React from 'react'
import { pagesPaths } from 'utils/path'

export interface ClientDoctorSelect {
  clientUrl?: string
  doctorUrl?: string
}

export const ClientDoctorSelectContext = React.createContext<ClientDoctorSelect>({
  clientUrl: pagesPaths.homeClient.path,
  doctorUrl: pagesPaths.homeDoctor.path
})
