import { ActivityIndicator } from 'atomic/legacy/atm.activity-indicator'
import { diactriclessKebabCase } from 'utils/string'
import { normalizeUrl } from 'utils/url'
import * as React from 'react'
import { isNullOrUndefined } from 'util'
import {
  activeClassName,
  ButtonContentStyled,
  ButtonSpinnerStyled,
  ButtonStyled,
  ButtonStyledNew,
  ButtonStyledW,
  ExternalLinkButtonStyled,
  ExternalLinkButtonStyledNew,
  ExternalLinkButtonStyledW,
  LinkButtonStyled,
  LinkButtonStyledNew,
  LinkButtonStyledW
} from './button.component.style'

export type ButtonKind = 'primary' | 'secondary' | 'link' | 'alert' | 'neutral'

export interface ButtonProps {
  /** Enables/disables a component. */
  disabled?: boolean
  /** Enables/disables full-width button. */
  expanded?: boolean
  /** Enables/disables loading indicator. */
  loading?: boolean
  /** Event. Fired when button is clicked. */
  onClick?: () => any
  /** Adds a <a target= /> attribute. Applies only when "to" props is declared. */
  target?: '_self' | '_parent' | '_blank' | '_top'
  /** When declared, button component is rendered as <a href.. />. Otherwise, it renders a <button /> element. */
  to?: string
  /** Changes button's visual style. */
  kind?: ButtonKind
  /**
   * why is it necessary? https://github.com/indigotech/eok-weekly/issues/114
   * https://www.w3schools.com/tags/att_button_type.asp
   */
  type?: 'button' | 'submit' | 'reset' // default-value: "button"
  /** decide between Gatsby link and a default <a> tag */
  external?: boolean
  // https://www.gatsbyjs.org/docs/gatsby-link/#add-custom-styles-for-the-currently-active-link
  showActiveClass?: boolean
  small?: boolean
  light?: boolean
  id?: string
  cursor? : string
}

interface WithLinkProps extends ButtonProps {
  children?: any
}

const getChildrenText = (children: any) => {
  if (typeof children === 'string') {
    return children
  }

  if (children && children.props && Array.isArray(children.props.children)) {
    return children.props.children.find(item => typeof item === 'string') as string
  }
  return null
}

const withLink = (Component, props: WithLinkProps) => {
  const { loading, disabled, type, expanded, small, light, external, showActiveClass, to, target, ...others } = props
  const href = external && props.to

  const hastext = isNullOrUndefined(props.to)
    ? undefined
    : typeof props.children === 'string' || (Array.isArray(props.children) && props.children.some(item => typeof item === 'string'))

  return (
    <Component
      // https://github.com/styled-components/styled-components/issues/1198#issuecomment-336621217
      expanded={expanded ? 1 : undefined}
      small={small ? 1 : undefined}
      light={light ? 1 : undefined}
      hastext={hastext ? 1 : undefined}
      disabled={loading || disabled}
      href={href ? normalizeUrl(href) : undefined}
      to={normalizeUrl(to)}
      type={!props.to ? type : undefined}
      // https://www.gatsbyjs.org/docs/gatsby-link/#add-custom-styles-for-the-currently-active-link
      activeClassName={showActiveClass && Component === LinkButtonStyled ? activeClassName : undefined}
      target={target}
      // https://developers.google.com/web/tools/lighthouse/audits/noopener
      rel={target === '_blank' ? 'noopener' : undefined}
      {...others}
      id={props.id}
      className={diactriclessKebabCase(getChildrenText(props.children))}
    >
      <ButtonContentStyled loading={loading || undefined} hastext={hastext} expanded={expanded}>
        {props.children}
      </ButtonContentStyled>
      <ButtonSpinnerStyled loading={loading || undefined}>
        <ActivityIndicator type="spinner" size="1x" />
      </ButtonSpinnerStyled>
    </Component>
  )
}

export class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    disabled: false,
    expanded: false,
    loading: false,
    kind: 'primary',
    type: 'button'
  }
  
  render() {
    return withLink(this.props.to ? (this.props.external ? ExternalLinkButtonStyled : LinkButtonStyled) : ButtonStyled, this.props)
  }
}

export class ButtonW extends React.Component<ButtonProps> {
  static defaultProps = {
    disabled: false,
    expanded: false,
    loading: false,
    kind: 'primary',
    type: 'button'
  }

  render() {
    return withLink(this.props.to ? (this.props.external ? ExternalLinkButtonStyledW : LinkButtonStyledW) : ButtonStyledW, this.props)
  }
}

export class ButtonNew extends React.Component<ButtonProps> {
  static defaultProps = {
    disabled: false,
    expanded: false,
    loading: false,
    kind: 'primary',
    type: 'button'
  }

  render() {
    return withLink(this.props.to ? (this.props.external ? ExternalLinkButtonStyledNew : LinkButtonStyledNew) : ButtonStyledNew, this.props)
  }
}