import { Border, Color, FontSize, FontWeight, Spacing } from 'atomic/legacy/obj.constants'
import { highlightStyle } from 'atomic/legacy/obj.mixin'
import ColorFunc from 'color'
import { Link } from 'gatsby'
import styled, { css, DefaultTheme, StyledProps } from 'styled-components'
import { ButtonKind, ButtonProps } from './button.component'

interface ButtonStyledProps extends ButtonProps {
  hastext?: boolean
}

export const buttonHeight = '48px'
export const buttonHeightSmall = '32px'
export const activeClassName = 'active'

export const ButtonStyledCss = css<ButtonStyledProps>`
  position: relative;
  width: auto;
  height: ${props => getHeight(props)};
  padding: ${props => getPadding(props)};
  width: ${props => (props.expanded ? '100%' : 'auto')};
  text-align: ${props => (props.expanded ? 'center' : 'left')};
  padding: 0 ${props => (props.small ? Spacing.Small : Spacing.XLarge)};;
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  font-size: ${FontSize.XSmall};
  letter-spacing: 1px;
  text-decoration: none;
  ${highlightStyle}
  cursor: ${props => (props.cursor ? props.cursor : props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled || props.loading ? 0.5 : 1)};
  /* this is useful when its father is pointer-events: none */
  pointer-events: auto;
  ${props =>
    props.kind === 'link' ? `${linkSpecificStyle(props)}` : `${buttonSpecificStyle(props)}`}
`

export const ButtonStyled = styled.button`
  ${ButtonStyledCss}

  &:focus {
    outline: 0;
  }
`
export const linkButtonStyled = css`
  ${ButtonStyledCss}

  &.${activeClassName} {
    border-bottom: 2px solid currentColor;
  }

  // This style improves how links are shown inside texts
  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }
`
export const ButtonStyledW = styled.button`
  ${ButtonStyledCss}
  text-decoration-line: underline;

  &:focus {
    outline: 0;
  }
`
export const linkButtonStyledW = css`
  ${ButtonStyledCss}
  text-decoration-line: underline;

  &.${activeClassName} {
    border-bottom: 2px solid currentColor;
  }

  // This style improves how links are shown inside texts
  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }
`

export const ButtonStyledNew = styled.button`
  ${ButtonStyledCss}
  font-size: 12px;

  &:focus {
    outline: 0;
  }
`
export const linkButtonStyledNew = css`
  ${ButtonStyledCss}
  font-size: 12px;

  &.${activeClassName} {
    border-bottom: 2px solid currentColor;
  }

  // This style improves how links are shown inside texts
  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }
`

export const ExternalLinkButtonStyled = styled.a`
  ${linkButtonStyled}
`
export const LinkButtonStyled = styled(Link as any)`
  ${linkButtonStyled}
`

export const ExternalLinkButtonStyledW = styled.a`
  ${linkButtonStyledW}
`
export const LinkButtonStyledW = styled(Link as any)`
  ${linkButtonStyledW}
`

export const ExternalLinkButtonStyledNew = styled.a`
  ${linkButtonStyledNew}
`
export const LinkButtonStyledNew = styled(Link as any)`
  ${linkButtonStyledNew}
`
export const ButtonContentStyled = styled.div<ButtonStyledProps>`
  transition: all 0.2s ease-in-out;
  opacity: ${props => (props.loading ? 0 : 1)};
  font-stretch: 100%;
  vertical-align: middle;
  margin: ${props => (props.expanded ? 'auto' : '')};

  & .fa,
  & img,
  & svg {
    padding-right: ${props => (props.hastext ? Spacing.Small : 0)};
  }
`

export const ButtonSpinnerStyled = styled.span`
  display: ${(props: { loading?: boolean }) =>
    props.loading ? 'inline-block' : 'none'} !important;
  position: absolute;
  right: calc(50% - 7px);
  top: calc(50% - 7px);

  & svg {
    padding: 0;
  }
`

const getHeight = (props): string => {
  if (props.kind === 'link') {
    return ''
  }
  if (props.small) {
    return buttonHeightSmall
  }
  return buttonHeight
}

const getPadding = (props): string => {
  if (props.kind === 'link') {
    return `0 ${Spacing.Small} 0 0;`
  }
  return `0 ${props.small ? Spacing.Large : Spacing.XLarge};`
}

const getBackGroundColor = (kind: ButtonKind, isLight: boolean, theme: DefaultTheme): string => {
  if (kind === 'primary') {
    return getLightMainColor(kind, isLight, theme)
  }
  return 'transparent'
}

const getTextColor = (kind: ButtonKind, isLight: boolean, theme: DefaultTheme): string => {
  if (kind !== 'primary') {
    return getLightMainColor(kind, isLight, theme)
  }
  if (isLight) {
    return theme.color.primary
  }
  return Color.White
}

const getBorderColor = (kind: ButtonKind, isLight: boolean, theme: DefaultTheme): string => {
  if (kind === 'primary') {
    return 'transparent'
  }
  return getLightMainColor(kind, isLight, theme)
}

const getLightMainColor = (kind: ButtonKind, isLight: boolean, theme: DefaultTheme): string => {
  if (isLight) {
    return Color.White
  }
  switch (kind) {
    case 'primary':
      return theme.color.primary
    case 'secondary':
      return theme.color.primary
    case 'link':
      return theme.color.primary
    case 'alert':
      return theme.color.alert
    case 'neutral':
      return theme.color.doctorAccessory
    default:
      ''
      // console.log("getLightMainColor: kind: error => invalid kind", kind)
      return theme.color.primary
  }
}

const linkSpecificStyle = (props: StyledProps<ButtonStyledProps>) => `
  display: inline-block;
  padding: 0;
  height: auto;
  background-color: transparent;
  border-color: transparent;
  color: ${getLightMainColor(props.kind, props.light, props.theme)};

  &:visited {
    color: ${ColorFunc(getLightMainColor(props.kind, props.light, props.theme))
      .hsl()
      .string()};
  }
  &:hover {
    ${
      props.disabled
        ? null
        : `color: ${ColorFunc(getLightMainColor(props.kind, props.light, props.theme))
            .darken(0.2)
            .hsl()
            .string()};`
    };
  }

  &:active {
    ${
      props.disabled
        ? null
        : `color: ${ColorFunc(getLightMainColor(props.kind, props.light, props.theme))
            .darken(0.3)
            .hsl()
            .string()};`
    }
  }
`

const buttonSpecificStyle = (props: StyledProps<ButtonStyledProps>) => `
  ${getButtonSpecificPadding(props)}
  display:inline-flex;
  align-items:center;
  text-align:center;

  min-height: ${props.small ? buttonHeightSmall : buttonHeight};

  background-color: ${getBackGroundColor(props.kind, props.light, props.theme)};
  color: ${getTextColor(props.kind, props.light, props.theme)};
  border-color: ${getBorderColor(props.kind, props.light, props.theme)};
  border-style: solid;
  border-width: ${Border.Width};
  border-radius: ${0.5 * parseInt(props.small ? buttonHeightSmall : buttonHeight, 10)}px;
  &:hover {
    ${props.disabled ? null : hoverStyle(props)}
  }

  &:active {
    ${props.disabled ? null : activeStyle(props)}
  }

  & .fa,
  & img,
  & svg {
    padding-right: ${Spacing.Small};
  }
`

const getButtonSpecificPadding = (props: StyledProps<ButtonStyledProps>) => {
  if (!props.hastext) {
    return `padding: ${Spacing.Small} ${Spacing.Medium};`
  }

  return `padding: 0 ${props.small ? Spacing.Small : Spacing.XLarge};`
}

const hoverStyle = props => `
  ${
    props.kind === 'secundary'
      ? `color: ${ColorFunc(getTextColor(props.kind, props.light, props.theme))
          .darken(0.2)
          .hsl()
          .string()};
      background-color: ${Color.GrayXLight};`
      : `background-color: ${ColorFunc(getBackGroundColor(props.kind, props.light, props.theme))
          .darken(0.1)
          .hsl()
          .string()}`
  }`

const activeStyle = props => `
  ${
    props.kind === 'secundary'
      ? `color: ${ColorFunc(getTextColor(props.kind, props.light, props.theme))
          .darken(0.3)
          .hsl()
          .string()};
      background-color: ${Color.GrayXLight};`
      : `background-color: ${ColorFunc(getBackGroundColor(props.kind, props.light, props.theme))
          .darken(0.2)
          .hsl()
          .string()}`
  }`
