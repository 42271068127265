export const diactriclessKebabCase = (str: string) => noDiactrics(kebabCase(str))

// https://gist.github.com/thevangelist/8ff91bac947018c9f3bfaad6487fa149#gistcomment-2183914
const kebabCase = (str: string) =>
  str
    ? str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
    : ''

const noDiactrics = (str: string) => {
  // remove-accents-diacritics: https://stackoverflow.com/a/37511463/3670829
  if ('normalize' in String.prototype) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  // https://stackoverflow.com/a/51693814/3670829
  const weird = 'öüóőúéáàűíÖÜÓŐÚÉÁÀŰÍçÇ!@£$%^&*()_+?/*."'
  const normalized = 'ouooueaauiOUOOUEAAUIcC                 '
  const lentext = str.toString().length - 1

  let newText = ''
  let idoff = -1
  for (let i = 0; i <= lentext; i = i + 1) {
    idoff = weird.search(str.charAt(i))
    if (idoff === -1) {
      newText = newText + str.charAt(i)
    } else {
      newText = newText + normalized.charAt(idoff)
    }
  }
  return newText
}

export const compareStringWithoutPrefixes = (aTitle: string, bTitle: string, prefixes: string[]) => {
  let a = aTitle.slice()
  let b = bTitle.slice()
  prefixes.forEach(prefix => {
    a = a.replace(prefix, '')
    b = b.replace(prefix, '')
  })
  if (a > b) {
    return 1
  }
  return -1
}
