import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome'
import { Color } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'
import { isNullOrUndefined } from 'util'

interface ActivityIndicatorStyledProps extends Props {
  light?: boolean
}

export const ActivityIndicatorStyled = styled(FontAwesomeIcon)<ActivityIndicatorStyledProps>`
  color: ${(props) => (isNullOrUndefined(props.light) ? 'inherit' : props.light ? Color.White : props.theme.color.primary)};
  display: inline-block;
`
