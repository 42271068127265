import React from 'react'
import { Col as FlexCol, Grid as FlexGrid, Row as FlexRow } from 'react-styled-flexboxgrid'
import { Spacing } from 'atomic/legacy/obj.constants'
import styled, { css } from 'styled-components'

export interface RowProps {
  mb?: boolean
  mt?: boolean
  noGutter?: boolean
  expanded?: boolean
}

export interface ColProps {
  gutter?: boolean
}

export const GridSettings = {
  flexboxgrid: {
    gridSize: 12,
    // Defaults
    gutterWidth: 1, // rem
    outerMargin: 1.5, // rem
    breakpoints: {
      xs: 0,
      sm: 48,
      md: 64,
      lg: 75
    }
  }
}

export const rowMargin = GridSettings.flexboxgrid.outerMargin + GridSettings.flexboxgrid.gutterWidth / 2

export const mobileGutterStyle = css`
  padding: 0 ${rowMargin}rem;
`

const GridStyled = styled(FlexGrid) <{ position: string }>`
  position: ${props => props.position || 'static'};
`

const RowStyled = styled(FlexRow)`
  margin-bottom: ${(props: RowProps) => (props.mb ? Spacing.Medium : '0px')};
  margin-top: ${(props: RowProps) => (props.mt ? Spacing.Medium : '0px')};

  ${(props: RowProps) => {
    if (props.expanded) {
      return `height: 100%;
              `
    }
    return ''
  }}

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;
              `
    }
    return ''
  }}

  &.NewSpacing {
    margin-bottom: ${(props: RowProps) => (props.mb ? Spacing.Small : '0px')};
  }
`

const RowStyledNew = styled(FlexRow)`
  margin-bottom: ${(props: RowProps) => (props.mb ? Spacing.Medium : '0px')};
  margin-top: ${(props: RowProps) => (props.mt ? Spacing.Medium : '0px')};
  justify-content: center;

  ${(props: RowProps) => {
    if (props.expanded) {
      return `height: 100%;
              `
    }
    return ''
  }}

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;
              `
    }
    return ''
  }}
`

const RowStyledWeinmann = styled(FlexRow)`
  margin-top: ${(props: RowProps) => (props.mt ? Spacing.Medium : '0px')};

  ${(props: RowProps) => {
    if (props.expanded) {
      return `height: 100%;
              `
    }
    return ''
  }}

  ${(props: RowProps) => {
    if (props.noGutter) {
      return `overflow: hidden;
              margin-left: -${rowMargin}rem;
              margin-right: -${rowMargin}rem;
              `
    }
    return ''
  }}
`

const ColStyled = styled(FlexCol)`
  ${(props: ColProps) => {
    if (props.gutter) {
      return mobileGutterStyle
    }
    return ''
  }}
`

const ColStyledNew = styled(FlexCol)`
  margin-bottom: 8px;
  ${(props: ColProps) => {
    if (props.gutter) {
      return mobileGutterStyle
    }
    return ''
  }}
`

const Separador = styled(FlexCol)`
width: 100%;
border: 1px solid #EFEFEF;
`

export const Grid = props => <GridStyled {...props} />
export const Col = props => <ColStyled {...props} />
export const ColNew = props => <ColStyledNew {...props} />
export const Row = props => <RowStyled {...props} />
export const RowNew = props => <RowStyledNew {...props} />
export const RowWeinmann = props => <RowStyledWeinmann {...props} />
export const Sep = props => <Separador {...props} />
