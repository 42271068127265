import { BodyStyle, H2Style, H3Style, H4Style } from 'atomic/legacy/atm.typography'
import { Color, FontSize, FontWeight, Spacing } from 'atomic/legacy/obj.constants'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyled = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background-color: ${props => props.theme.color.background};
  }
  a {
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0.05);
    color: ${props => props.theme.color.primary};
  }
  p {
    ${BodyStyle}
  }

  .responsive-table-wrapper {
    position: relative;
    overflow-x: auto;
  }

  table {
    border:none;
    border-collapse: collapse;
  }
  table td, table th {
    border-left: 1px solid ${Color.GrayXLight};
    border-right: 1px solid ${Color.GrayXLight};
  }
  table td:first-child {
    border-left: none;
  }
  table td:last-child {
    border-right: none;
  }
  tr:first-child {
    > th {
      color: ${Color.White};
      font-size: ${FontSize.XXSmall};
      font-family: ${props => props.theme.typography.primary};
      font-weight: ${FontWeight.SemiBold};
      text-align: left;
      vertical-align: top;
      background: ${Color.GrayXDark};
      padding: ${Spacing.Small} ${Spacing.Medium};
    }
  }
  td {
      color: ${Color.GrayDark};
      font-size: ${FontSize.XXSmall};
      font-family: ${props => props.theme.typography.primary};
      font-weight: ${FontWeight.Normal};
      text-align: left;
      vertical-align: top;

      padding: ${Spacing.Medium};
  }

  li {
    ${BodyStyle}
  }
  p + p {
    margin-top: ${Spacing.Medium}
  }
  h2 {
    ${H2Style}
  }
  h3 {
    ${H3Style}
  }
  h4 {
    ${H4Style}
  }

  /* CosmicJS classes naming convention nested in the BodyWrapper */
  .fr-video{
    height: 0;
    position: relative;
    /* https://css-tricks.com/aspect-ratio-boxes/ */
    padding-top: 56.25%;
    display: block;

    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .fr-fir {
    margin-right: 0;
    text-align: right;
  }
  .fr-fil {
    margin-left: 0;
    text-align: left;
  }
  .fr-dib {
    margin: 5px auto;
    display: block;
    float: none;
  }
`
