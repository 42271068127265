import { getStorage, StorageType } from './storage/storage.provider'

const VISIT_KEY = 'SiteVisit'

class VisitCountDataSource {
  private localStorage = getStorage(StorageType.Local)

  get visitCount() {
    return this.localStorage.get(VISIT_KEY) || 0
  }
  set visitCount(value: number) {
    this.localStorage.put(VISIT_KEY, value)
  }

  incrementVisitCount() {
    this.visitCount = this.visitCount + 1
  }

  resetVisitCount() {
    this.visitCount = 0
  }
}

export default new VisitCountDataSource()
